import React from "react"

const Tools = ({ title, content, stage, links }) => {
    return (
        <div className="col-12 startup-toolbox my-5">
            <div className="card">
                <div className="card-body p-0">
                    <h3 className="card-title font-weight-bold">{title}</h3>
                    <p className="card-text font-weight-light" style={{ fontSize: "1.1em" }}>{content}</p>
                    {links && links.map(link => {
                        return (
                            <a href={link.url} className="font-weight-light d-block"><span className="font-weight-bold" style={{ color: "#000" }}>»</span> {link.text}</a>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

export default Tools
