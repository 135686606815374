import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tool from "../components/tools"

import tools from "../../content/data/toolbox.json"

const StartupToolbox = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    const stage0 = tools.filter((tool) => {
        return tool.stage === 0
    })
    const stage1 = tools.filter((tool) => {
        return tool.stage === 1
    })
    const stage2 = tools.filter((tool) => {
        return tool.stage === 2
    })

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title="Startup Toolbox"
                description="Essential tools and techniques for your startup idea."
                image="/spacex-landing.jpg"
            />
            <h1 className="display-4 mt-3 mb-5">Startup Toolbox</h1>
            <p className="lead mb-5">A concise list of practical tools and techniques to boost your startup.<br />With handpicked links for further learning.</p>

            <div className="row">
                <div className="col">
                    <h2 className="font-weight-light text-center">Ideation</h2>
                    {stage0.map(item => {
                        return (
                            <Tool
                                title={item.title}
                                content={item.content}
                                stage={item.stage}
                                links={item.links}
                            />
                        )
                    })}

                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h2 className="font-weight-light text-center">Validation</h2>
                    {stage1.map(item => {
                        return (
                            <Tool
                                title={item.title}
                                content={item.content}
                                stage={item.stage}
                                links={item.links}
                            />
                        )
                    })}
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h2 className="font-weight-light text-center">Growth</h2>
                    {stage2.map(item => {
                        return (
                            <Tool
                                title={item.title}
                                content={item.content}
                                stage={item.stage}
                                links={item.links}
                            />
                        )
                    })}
                </div>
            </div>


            <div className="alert alert-light" role="alert">
                <p>By clicking one of the links above, I may earn a small provision in case you buy something.<br />
        This does not cost you anything. <strong>Thanks a lot!</strong> 👍</p>
            </div>

        </Layout>
    )
}

export default StartupToolbox

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

